import './App.css';
import frontLogo from './art/top.png'
import backLogo from './art/gear.png'


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>V.O.L.T</h1>
        <p>Virtual Organization & Logistics Toolkit</p>
        <div className='logo-wrapper'>
          <img src={backLogo} className="App-logo" alt="volt-logo-gears" />
          <img src={frontLogo} className="App-logo-front" alt="volt-logo-front" />
        </div>
        <p>
          Modern software for the modern builder.
        </p>
      </header>
    </div>
  );
}

export default App;
